import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
	redirect: '/index'
  },
  {
	  path:'/index',
	  name: 'index',
	  component: () => import('../views/index/index.vue')
  },
  {
  	  path:'/solution',
  	  name: 'solution',
  	  component: () => import('../views/solution/index.vue'),
	  meta: {
		  keepAlive: true,
	  },
  },
  {
  	  path:'/join',
  	  name: 'join',
  	  component: () => import('../views/join/index.vue'),
	  meta: {
		  keepAlive: true,
	  },
  },
  {
  	  path:'/about',
  	  name: 'about',
  	  component: () => import('../views/about/index.vue'),
	  meta: {
		  keepAlive: true,
	  },
  },
  {
  	  path:'/detail',
  	  name: 'detail',
  	  component: () => import('../views/detail/index.vue'),
	  meta: {
		  keepAlive: true,
	  },
  },
  {
  	  path:'/success',
  	  name: 'success',
  	  component: () => import('../views/success/index.vue'),
	  meta: {
		  keepAlive: true,
	  },
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
