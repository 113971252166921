import getters from './getters'
import { createStore } from 'vuex'
import type from './modules/type'

export default createStore({
  state: {
  },
  getters,
  mutations: {
  },
  actions: {
  },
  modules: {
    type
  }
})
