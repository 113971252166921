<template>
	<div :class="['suspension', 'header']">
		<!--顶部导航开始-->
		<nav :class="['showNav', { navColor: path == '/index' }]">
			<template v-if="path == '/index'">
				<div class="bgImg"></div>
			</template>
			<template v-else>
				<div class="bgImg bgImg2"></div>
				<!-- <img src="@/static/logo/logo-2.png"  class="logoImg"> -->
			</template>
			<!--为了防止出现闪屏，将空余部分填满-->
			<div style="width: 15%;"></div>
			<!-- pc 端头部 -->
			<div style="width: 70%" class="pcHeader">
				<ul v-for="item in state.navigation" :key="item.id" @click="goto($event, item)" class="dropdown"
					@mouseenter="moveFn(item)" @mouseleave="moveFn(item)">
					{{ item.title }}
					<transition name="anima" mode='in-out'>
						<div class="dropdown_wrap" v-show='item.showFalg'>
							<li v-for="(i, index) in item.content">{{ i.name }}</li>
						</div>
					</transition>
				</ul>
			</div>
			<!-- 移动端 头部 -->
			<div style="width: 70%" class="mobileHeader">
				<div :class="['menu-icon', btnFlag === 1 ? 'on' : '']" @click="handleBtn">
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</nav>
		<div :class="['menu_wrap', btnFlag === 1 ? 'menu_wrap_show' : btnFlag === 2 ? 'menu_wrap_hidden' : '']"
			id="menu_warp">

			<van-collapse :class="[btnFlag === 1 ? 'menu_wrap_show' : btnFlag === 2 ? 'menu_wrap_hidden' : '']"
				v-model="activeNames" accordion>
				<van-collapse-item :title="item.title" :readonly="item.content.length == 0" v-for="item in state.navigation"
					:key="item.id" @click="goto_mobile(item, '')">
					<div v-for="(child, index) in item.content" style="height: 30px;"
						@click.stop="goto_mobile(item, child)">
						{{ child.name }}
					</div>
				</van-collapse-item>
			</van-collapse>
		</div>
		<!--顶部导航结束-->
	</div>
</template>

<script>
import { useRoute, useRouter } from "vue-router"
import { ref, watchEffect, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { createApp } from 'vue';
import { Collapse, CollapseItem } from 'vant';


export default {
	setup() {
		let activeNames = ref();
		function closeNav() {
			activeNames.value = ''
			btnFlag.value = 2
		}
		function goto_mobile(item, child) {
			// 处理父级逻辑
			let currentPath = path.value
			let obj = {}
			let returnfalg = false
			if (!child) {
				// 首页
				if (currentPath == '/index') {
					switch (item.title) {
						case '最新动态':
							document.getElementById('actionBox').scrollIntoView({ behavior: "smooth" })
							closeNav()
							break;
						case '首页':
							closeNav()
							break;
					}
				}  // 其他页面
				else {
					if (item.content.length == 0) {
						item.title == '最新动态' && (obj.selectID = 'actionBox')
						router.push({ path: item.to, query: {...obj} })
						closeNav()
						return
					}
				}
				return false
			}

			switch (item.title) {
				case '解决方案': {
					obj.solutionId = child.id
					break;
				}
				case '成功案例': {
					if (currentPath == '/index' ) {
						document.getElementById('successBox').scrollIntoView({ behavior: "smooth" })
						returnfalg = true
					}
					else {
						obj.selectID = 'successBox'
					}
					break;
				}
				case '加入我们': {
					let scrollId = ''
						switch (child.name) {
							case '社会招聘' : {
								scrollId = 'recruit'
								break;
							}
							case '员工福利' : {
								scrollId = 'welfare'
								break;
							}
							case '员工活动' : {
								scrollId = 'activity'
								break;
							}
						}
					//  当前页 进行锚点操作
					if (currentPath == item.to) {
						document.getElementById(scrollId).scrollIntoView({ behavior: "smooth" })
						returnfalg = true
					}
					//  其他页
					else {
						obj.selectID = scrollId
					}
					break;

				}
				case '关于清汇': {
					let scrollId = ''
						switch (child.name) {
							case '公司介绍' : {
								scrollId = 'introduce'
								break;
							}
							case '发展历程' : {
								scrollId = 'progress'
								break;
							}
							case '公司文化' : {
								scrollId = 'culture'
								break;
							}
							case '联系我们' : {
								scrollId = 'contact'
								break;
							}
						}
					//  当前页 进行锚点操作
					if (currentPath == item.to) {
						document.getElementById(scrollId).scrollIntoView({ behavior: "smooth" })
						returnfalg = true
					}
					//  其他页
					else {
						obj.selectID = scrollId
					}
					break;

				}
			}
			closeNav()
			if (returnfalg) return 
			router.push({ path: item.to, query: { ...obj  } })
			

		}
		const app = createApp();
		app.use(Collapse);
		app.use(CollapseItem);

	
		// return { activeNames };



		// 判断当前机型 
		let { getters } = useStore();
		let isMobile = computed(() => { return getters.isMobile })
		console.log('@@@@@', isMobile.value)

		// 控制按钮
		let btnFlag = ref(0)
		const fn = function (e) {
			e.stopPropagation()
		}

		const handleBtn = () => {
			btnFlag.value = [0, 2].includes(btnFlag.value) ? 1 : 2
		}


		const route = useRoute()
		const router = useRouter()

		let path = ref('')
		watchEffect(() => {
			// 接收路由参数
			path.value = route.path
		})

		//锚点跳转到其他页面
		let goto = (e, item) => {


			let page = '/index'
			let selectID = ''
			let { id } = item
			let { nodeName, innerText } = e.target
			//  解决方案下拉框跳转
			if (id == 1 && nodeName == 'LI') {
				let title = innerText
				//跳转到解决方案详情
				let solutionId = ''
				state.navigation.forEach(item => {
					if (item.id != id) return
					item.content.forEach(childItem => {
						if (childItem.name == title)
							solutionId = childItem.id
					})
				})
				return router.push({ path: 'solution', query: { solutionId, date: new Date().getTime() } })
			}
			// 处理加入我们
			if (id == 4 && nodeName == 'LI') {
				switch (innerText) {
					case '社会招聘':
						selectID = 'recruit'
						break;
					case '员工福利':
						selectID = 'welfare'
						break;
					case '员工活动':
						selectID = 'activity'
						break;
					default:
						selectID = ''
						break;
				}
				return router.push({ path: 'join', query: { selectID } })
			}

			switch (id) {
				case 0:
					selectID = ''
					break;
				case 1:
					selectID = 'solutionBox'
					break;
				case 2:
					selectID = 'successBox'
					break;
				case 3:
					selectID = 'actionBox'
					break;
				case 4:
					selectID = 'joinUsBox'
					break;
				case 5:
					page = '/about'
					break;
				default:
					alert("无效的输入")
			}

			// 处理关于清汇下拉框跳转
			if (page == '/about') {
				// if (nodeName != 'LI') return 
				switch (innerText) {
					case '公司介绍':
						selectID = 'introduce'
						break;
					case '发展历程':
						selectID = 'progress'
						break;
					case '公司文化':
						selectID = 'culture'
						break;
					case '联系我们':
						selectID = 'contact'
						break;
					default:
						selectID = ''
						break;
				}
			}
			if (page == route.path) {
				if (selectID)
					//如果当前已经在这个页面不跳转 直接去
					document.getElementById(selectID).scrollIntoView({ behavior: "smooth" })


			} else {
				//否则跳转路由
				// localStorage.setItem("toId", selectID);
				router.push({ path: page, query: { selectID } });
			}
		}

		const state = reactive({
			// 定义导航栏数据
			navigation: []
		})
		state.navigation = [{
			id: 0,
			title: '首页',
			to: '/index',
			content: [],
		}, {
			id: 1,
			title: '解决方案',
			to: '/solution',
			content: [
				{
					name: '智慧社区',
					id: '8c60617a-9c9c-44a8-92af-4180d5ce8070'
				},
				{
					name: 'O2O新零售',
					id: '68f26d6a-3fb6-4d68-9ce9-6487995c3146'
				},
				{
					name: '互联网+教育培训',
					id: '3f923374-f655-4836-ac23-84b03010b4d4'
				},
				{
					name: '校友平台',
					id: '0e3a218d-1ca4-4a57-b9a5-813ef45f636f'
				},
				{
					name: '定制化开发解决方案',
					id: 'aedb173c-f6c7-49ed-bb8c-64aa471eca3c'
				},
			],
			showFalg: false
		}, {
			id: 2,
			title: '成功案例',
			to: '/index',
			content: [
				{
					name: '智慧教育',
					id: ''
				},
				{
					name: '智慧社区',
					id: ''
				},
				{
					name: '新零售',
					id: ''
				},
				{
					name: '其他',
					id: ''
				},
			],
			showFalg: false
		}, {
			id: 3,
			title: '最新动态',
			to: '/index',
			content: [],
			showFalg: false
		}, {
			id: 4,
			title: '加入我们',
			to: '/join',
			content: [
				{
					name: '社会招聘',
					id: ''
				},
				{
					name: '员工福利',
					id: ''
				},
				{
					name: '员工活动',
					id: ''
				},
			],
			showFalg: false
		}, {
			id: 5,
			title: '关于清汇',
			to: '/about',
			content: [
				{
					name: '公司介绍',
					id: ''
				},
				{
					name: '发展历程',
					id: ''
				},
				{
					name: '公司文化',
					id: ''
				},
				{
					name: '联系我们',
					id: ''
				}
			],
			showFalg: false
		}]

		// 鼠标移入移出头部标题标识
		let moveFlag = ref(false)
		const moveFn = function (item) {
			item.showFalg = !item.showFalg
		}


		return {
			moveFlag, path, goto, state, moveFn, isMobile, btnFlag, handleBtn, activeNames, goto_mobile
		}
	}

}
</script>

<style lang="scss" scoped>
$menuMaxHeight: calc(100vh - 60px);
$themeColor: #0d8cd3;

// pc 端 下拉出现效果
.anima-enter-from,
.anima-leave-to {
	opacity: 0;
}

.anima-leave-from,
.anima-enter-to {
	opacity: 1;
}

.anima-enter-active,
.anima-leave-active {
	transition: opacity 1s ease;
}

// .showNav:hover+div {
// 	display: block;
// }

.navColor {
	color: #fff;
}


@keyframes move {

	/*设置animation动画参数*/
	0% {
		/*动画开始时*/
		max-height: 0;
		overflow: hidden;
	}

	100% {
		/*动画结束时*/
		max-height: $menuMaxHeight;
		overflow: scroll;
	}
}

@keyframes hidden {

	/*设置animation动画参数*/
	0% {
		/*动画开始时*/
		max-height: $menuMaxHeight;
		overflow: scroll;
	}

	100% {
		/*动画结束时*/
		max-height: 0;
		overflow: hidden;
	}
}

.menu_wrap {
	width: 100%;
	// background: white;
	background: rgba(255, 255, 255, .75);
	position: absolute;
	top: 60px;
	overflow: hidden;
	max-height: 0;
	opacity: 0;
	// background: rgba(233, 239, 244,0.85);
	// background: rgba(0, 0, 0, 0);
	// margin-top: -4.9rem;

}

.menu_wrap_show {
	max-height: $menuMaxHeight;
	animation: move 2s;
	animation-fill-mode: forwards !important;
	overflow: scroll;
	opacity: 1;
}

.menu_wrap_hidden {
	overflow: hidden;
	max-height: 0;
	animation: hidden 1s;
	animation-fill-mode: forwards !important;
	opacity: 0;
}


// .logoImg {
// 	width: 16%;
// 	height:auto;
// 	@media only screen and (min-width: $minWidth) and (max-width:$maxWidth) {
// 		object-fit: contain;
// 		height: 30px !important;
// 		width: 150px !important;
// 	}
// }
.bgImg2 {
	background-image: url('@/static/logo/logo-2.png') !important;
}

.bgImg {
	background-image: url('@/static/logo/logo.png');
	width: 16%;
	height: 100%;
	background-size: 100% auto;
	background-repeat: no-repeat;

	&:hover {
		background-image: url('@/static/logo/logo-2.png');
	}

	@media only screen and (min-width: $minWidth) and (max-width:$maxWidth) {
		background-size: 100% 100%;
		// background-repeat: no-repeat
		height: 30px !important;
		width: 130px !important;
		background-image: url('@/static/logo/logo-2.png');
	}
}

::v-deep .van-cell__title,
.van-cell__value {
	color: $themeColor;
}

.suspension {
	position: absolute;
	top: 0;
	z-index: 1;
	left: 50%;
	transform: translate(-50%);
	width: 100%;

	@media only screen and (min-width: $minWidth) and (max-width:$maxWidth) {

		border-bottom: 1px solid #d2d2d2;
	}
}

.header {
	padding: 1.9rem 0;

	@media only screen and (min-width: $minWidth) and (max-width:$maxWidth) {
		position: relative;
		// background-color: rgba(0, 0, 0, .75);
		padding: 0;
	}

	nav {
		width: 63%;
		margin: 0 auto;
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		align-items: center;
		height: 3rem;
		position: relative;

		.pcHeader {
			display: block;
		}

		.mobileHeader {
			display: none;
		}

		@media only screen and (min-width: $minWidth) and (max-width:$maxWidth) {
			width: 100%;
			height: 60px;
			padding: 0 20px;

			.pcHeader {
				display: none;
			}

			.mobileHeader {
				display: block;

				.menu-icon {
					width: 30px;
					height: 30px;
					position: absolute;
					right: 20px;
					// top: 55%;
					// transform: translateY(-50%);
					transform: scale(.6);
					top: 30%;

					div {
						width: 100%;
						height: 3px;
						border-radius: 5px;
						background: $themeColor;
						position: absolute;
						left: 0;
						transition: all 0.5s;
						-webkit-transition: all 0.5s;
					}

					div:nth-child(2) {
						top: 10px;
					}

					div:nth-child(3) {
						top: 20px;
					}
				}

				.on {
					div:nth-child(1) {
						transform: translate(0, 10px) rotate(45deg);
						-webkit-transform: translate(0, 10px) rotate(45deg);
					}

					div:nth-child(2) {
						transform: rotate(-45deg);
						-webkit-transform: rotate(-45deg);
					}

					div:nth-child(3) {
						display: none;
					}
				}
			}
		}

		// 移动端 样式




		//  pc 端样式
		ul.dropdown {
			float: left;
			display: block;
			width: 7.1rem;
			height: 3rem;
			line-height: 3rem;
			text-align: center;
			font-size: 1rem;
			cursor: pointer;
			position: relative;


			.dropdown_wrap {
				// visibility: hidden;
				background-color: rgba(255, 255, 255, .75);
				color: black;
				border-radius: .5rem;
				position: absolute;
				box-sizing: border-box;
				padding: 0 1rem;
				left: 50%;
				transform: translateX(-50%);

				li {
					font-size: 0.75rem;
					white-space: nowrap;
				}
			}
		}




	}
}
</style>
