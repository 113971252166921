<template>
	<div class="index" >
		<Header> </Header>
		<router-view v-slot="{ Component }" :key="$route.fullPath">
			<keep-alive>
				<component :is="Component"></component>
			</keep-alive>
		</router-view>
		<Footer> </Footer>
	</div>
</template>

<script>
	import Header from '@/components/header/index.vue'
	import Footer from '@/components/footer/index.vue'

	export default {
		data() {
			return {

			}
		},
		components: {
			Header,
			Footer
		},
	}
</script>
<style lang="scss">
	.index {
		background-color: rgb(241,245,248);
		overflow: hidden;
	}

</style>
