<template>
	<div>
		<div class="footer">
			<div class="horizontal"></div>
			<div style="display: flex">
				<div>
					<ul v-for="item in list" :key="item.id" @click="goto($event, item)">
						{{ item.title }}
						<li v-for="(i, index) in item.content" :key="index">{{ i.name }}</li>
					</ul>
					<span><a href="https://beian.miit.gov.cn/" target="_blank">Copyright ©2014-2022</a></span>
					<span>QingHui Information Technology. All Rights Reserved. 广东清汇信息科技股份有限公司版权所有</span>
					<span class="beian"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备15082050号</a></span>
				</div>
				<div class="logo">
					<img src="@/static/logo/logo-2.png">
				</div>
			</div>
		</div>

		<!-- 移动端 -->
		<div class="footer_mobile">
			<div class="horizontal"></div>
			<van-collapse v-model="activeNames" accordion>
				<van-collapse-item :title="item.title" v-for="item in list" :key="item.id">
					<div v-for="(child, index) in item.content" style="height: 30px;" @click="goto_mobile(item,child)">
						{{ child.name }}
					</div>
				</van-collapse-item>
			</van-collapse>
			<span><a href="https://beian.miit.gov.cn/" target="_blank">Copyright ©2014-2022</a></span>
			<span>QingHui Information Technology. All Rights Reserved <p/>广东清汇信息科技股份有限公司版权所有</span>
			<span class="beian"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备15082050号</a></span>
			<!-- <div class="logo">
				<img src="@/static/logo/logo-2.png">
			</div> -->
		</div>
		<div style="height: 1rem"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list: [{
				id: 0,
				title: '解决方案',
				to: '/solution',
				content: [
					{
						name: '智慧社区',
						id: '8c60617a-9c9c-44a8-92af-4180d5ce8070'
					},
					{
						name: 'O2O新零售',
						id: '68f26d6a-3fb6-4d68-9ce9-6487995c3146'
					},
					{
						name: '互联网+教育培训',
						id: '3f923374-f655-4836-ac23-84b03010b4d4'
					},
					{
						name: '校友平台',
						id: '0e3a218d-1ca4-4a57-b9a5-813ef45f636f'
					},
					{
						name: '定制化开发解决方案',
						id: 'aedb173c-f6c7-49ed-bb8c-64aa471eca3c'
					},
				],
			}, {
				id: 1,
				title: '成功案例',
				to: '/index',
				content: [
					{
						name: '智慧教育',
						id: ''
					},
					{
						name: '智慧社区',
						id: ''
					},
					{
						name: '新零售',
						id: ''
					},
					{
						name: '其他',
						id: ''
					},
				],
			}, {
				id: 2,
				title: '加入我们',
				to: '/join',
				content: [
					{
						name: '社会招聘',
						id: ''
					},
					{
						name: '员工福利',
						id: ''
					},
					{
						name: '员工活动',
						id: ''
					},
				],
			}, {
				id: 3,
				title: '关于清汇',
				to: '/about',
				content: [
					{
						name: '公司介绍',
						id: ''
					},
					{
						name: '发展历程',
						id: ''
					},
					{
						name: '公司文化',
						id: ''
					},
					{
						name: '联系我们',
						id: ''
					}
				],
			}],
			activeNames: '',
		}
	},
	methods: {
		//锚点跳转到其他页面
		goto(e, item) {
			let page = '/index'
			let selectID = ''
			let { id } = item
			let { nodeName, innerText } = e.target

			//  解决方案下拉框跳转
			if (id == 0 && nodeName == 'LI') {
				let title = innerText
				//跳转到解决方案详情
				let solutionId = ''
				this.list.forEach(item => {
					if (item.id != id) return
					item.content.forEach(childItem => {
						if (childItem.name == title)
							solutionId = childItem.id
					})
				})
				return this.$router.push({ path: 'solution', query: { solutionId, date: new Date().getTime() } })
			}

			// 处理加入我们
			if (id == 2 && nodeName == 'LI') {
				switch (innerText) {
					case '社会招聘':
						selectID = 'recruit'
						break;
					case '员工福利':
						selectID = 'welfare'
						break;
					case '员工活动':
						selectID = 'activity'
						break;
					default:
						selectID = ''
						break;
				}
				return this.$router.push({ path: 'join', query: { selectID } })
			}

			switch (id) {
				case 0:
					selectID = 'solutionBox'
					break;
				case 1:
					selectID = 'successBox'
					break;
				case 2:
					selectID = 'joinUsBox'
					break;
				case 3:
					page = '/about'
					break;
				case 4:
					console.log("联系我们");
					break;
				default:
					console.log("无效的输入");
			}

			// 处理关于清汇下拉框跳转
			if (page == '/about') {
				// if (nodeName != 'LI') return 
				switch (innerText) {
					case '公司介绍':
						selectID = 'introduce'
						break;
					case '发展历程':
						selectID = 'progress'
						break;
					case '公司文化':
						selectID = 'culture'
						break;
					case '联系我们':
						selectID = 'contact'
						break;
					default:
						selectID = ''
						break;
				}
			}
			if (page == this.$route.path) {
				//如果当前已经在这个页面不跳转 直接去
				if (selectID)
					document.getElementById(selectID).scrollIntoView({ behavior: "smooth" })
			} else {
				//否则跳转路由
				this.$router.push({ path: page, query: { selectID } });
			}
		},

		goto_mobile(item,child) {
			let currentPath = this.$route.path
			let obj = {}
			let returnfalg = false
			this.activeNames = ''
			switch (item.title) {
				case '解决方案': {
					obj.solutionId = child.id
					break;
				}
				case '成功案例': {
					if (currentPath == '/index' ) {
						document.getElementById('successBox').scrollIntoView({ behavior: "smooth" })
						returnfalg = true
					}
					else {
						obj.selectID = 'successBox'
					}
					break;
				}
				case '加入我们': {
					let scrollId = ''
						switch (child.name) {
							case '社会招聘' : {
								scrollId = 'recruit'
								break;
							}
							case '员工福利' : {
								scrollId = 'welfare'
								break;
							}
							case '员工活动' : {
								scrollId = 'activity'
								break;
							}
						}
					//  当前页 进行锚点操作
					if (currentPath == item.to) {
						document.getElementById(scrollId).scrollIntoView({ behavior: "smooth" })
						returnfalg = true
					}
					//  其他页
					else {
						obj.selectID = scrollId
					}
					break;

				}
				case '关于清汇': {
					let scrollId = ''
						switch (child.name) {
							case '公司介绍' : {
								scrollId = 'introduce'
								break;
							}
							case '发展历程' : {
								scrollId = 'progress'
								break;
							}
							case '公司文化' : {
								scrollId = 'culture'
								break;
							}
							case '联系我们' : {
								scrollId = 'contact'
								break;
							}
						}
					//  当前页 进行锚点操作
					if (currentPath == item.to) {
						document.getElementById(scrollId).scrollIntoView({ behavior: "smooth" })
						returnfalg = true
					}
					//  其他页
					else {
						obj.selectID = scrollId
					}
					break;

				}
			}
			if (returnfalg) return 
			this.$router.push({ path: item.to, query: { ...obj  } })
		}
	},
}
</script>

<style scoped lang="scss">
.footer {
	@media only screen and (min-width: $minWidth) and (max-width:$maxWidth) {
		display: none;
	}

	display: block;
	width: 63%;
	margin: 5rem auto;
	font-size: 1rem;
	font-weight: 400;
	line-height: 2.6rem;
	font-family: Microsoft YaHei;

	.horizontal {
		border-bottom: #C9C8C8 1px solid;
		margin-bottom: 0.78rem;
	}

	ul {
		display: inline-block;
		vertical-align: top;
		padding-right: 7.1rem;
		margin-bottom: 3.3rem;
		cursor: pointer;

		li {
			font-size: 0.83rem;
			color: #666;
			height: 2rem;
		}
	}

	span {
		color: #898D8D;
		font-size: 0.6rem;
		height: 1.5rem;
	}

	.logo {
		display: flex;
		justify-content: space-around;
		flex-direction: column;

		img {
			width: 11rem;
			height: 2.97rem;
		}
	}
}

.footer_mobile {
	display: none;

	@media only screen and (min-width: $minWidth) and (max-width:$maxWidth) {
		width: 90%;
		margin: 20px auto 10px;
		display: block;

		.horizontal {
			border-bottom: #C9C8C8 1px solid;
			margin-bottom: 10px;
		}

		// vant css样式
		::v-deep .van-cell {
			background: none;
			border: solid 1px #737373;
			font-size: initial !important;
			color: black !important;
		}

		::v-deep .van-collapse-item {
			margin-bottom: 10px;
		}
	}
}

.beian:hover {
	color: #777;
}
</style>