import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/index.scss'//全局样式 
import Vant from 'vant';
import 'vant/lib/index.css';
import './util/flexible.js'

store.dispatch('type/setIsMobile')

//跳转新页面回到顶部
router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
    document.documentElement.scrollTop = 0;
});

createApp(App).use(store).use(router).use(Vant).mount('#app')
