import {isMobile} from '@/util/common.js'

const state = {
    isMobile : false
}

const mutations = {
    SET_IsMobile (state, isMobile) {
        state.isMobile = isMobile
    }
}

const actions = {
    setIsMobile ({commit}) {
        console.log('isMobile')
        let mobileType = isMobile()
        if (!mobileType) {
            mobileType = window.innerWidth < 700
        }
        commit('SET_IsMobile',mobileType)
    },
    setResize ({commit},mobileType) {
        console.log('resize',mobileType)
        commit('SET_IsMobile',mobileType)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  